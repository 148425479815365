import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import high_bandwidth from "../../images/ethernet-service/info/high_bandwidth.svg";
import single_provider from "../../images/ethernet-service/info/single_provider.svg";
import wholesale from "../../images/ethernet-service/info/wholesale.svg";

export default function EthernetService() {

  return (
    <div className="tab-connection">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-desc">
              {"Our reliable end-to-end ethernet services offer high-speed layer 2 to businesses. Our uncontended network means you're getting the speeds that are offered"}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-5">
          <Col xs={12} md>
            <img src={high_bandwidth} className="tab-img mr-3" alt="high speed fibre" />
            <div className="tab-title">
              {"High Bandwidth"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Our ethernet services are scalable depending on your business requirements; from 10Mbps to 10Gbps. Our Network is uncontended, so you receive the speeds that you pay for."}
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col xs={12} md>
            <img src={single_provider} className="tab-img mr-3" alt="experience difference" />
            <div className="tab-title">
              {"Single Provider"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Cut out the middle-man; VostroNet deploys, maintains and upgrades the network. Have comfort knowing you are dealing with a single, dedicated account manager."}
            </div>
          </Col>
        </Row>

        <Row className="my-5">
          <Col xs={12} md>
            <img src={wholesale} className="tab-img mr-3" alt="Integrated Wi-Fi Mesh" />
            <div className="tab-title">
              {"Wholesale Provider"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"We deliver wholesale to buildings, saving customer money by passing on wholesale prices."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
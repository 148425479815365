import React from "react";
import {Container, Row, Col} from "react-bootstrap";

const InformationSection = ({data = {}}) => {
  const {title, subtitle, subData = [], col} = data;
  const columnSize = {
    sm: 12,
    lg: col ? col : Math.floor(12 / subData.length),
  };

  return (
    <div className="py-5 information-section">
      <Container>
        {title && <Row>
          <Col>
            <div className="info-title my-2">
              {title}
            </div>
          </Col>
        </Row>}
        {subtitle && <Row>
          <Col>
            <div className="info-subtitle my-4">
              {subtitle}
            </div>
          </Col>
        </Row>}
        <Row>
          {subData.map(({title, subtitle, img}, i) => {
            return (
              <Col key={i} className="mb-3" {...columnSize}>
                <Container fluid className="information">
                  <Row className="align-items-center">
                    <Col xs={4} className="no-padding">
                      <img className="info-img" src={img} alt={`${title} icon`} />
                    </Col>
                    <Col xs={8} >
                      <div className="title mb-2">
                        {title}
                      </div>
                      <div className="subtitle">
                        {subtitle}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default InformationSection;

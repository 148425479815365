import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import licenced_carrier_icon from "../../images/dark-fibre/info/licenced_carrier_icon.svg";
import quality_infras_icon from "../../images/dark-fibre/info/quality_infras_icon.svg";
import highspeed_soln from "../../images/dark-fibre/info/highspeed_soln.svg";

export default function DarkFibre() {

  return (
    <div className="tab-connection">
      <Container fluid className="p-4">
      <Row>
        <Col>
          <div className="tab-main-title mb-3">
            {"Future-proof fibre network"}
          </div>
        </Col>
      </Row>
        <Row>
          <Col>
            <div className="tab-desc">
              {"VostroNet manages the connection from the site all the way through to the data centre. We own and operate our own fibre network, meaning we control the network, providing great user experience"}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-5">
          <Col xs={12} md>
            <img src={licenced_carrier_icon} className="tab-img mr-3" alt="high speed fibre" />
            <div className="tab-title">
              {"Licensed Carrier"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"VostroNet owns and maintains dedicated fibre connections across Asia-Pacific region."}
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col xs={12} md>
            <img src={quality_infras_icon} className="tab-img mr-3" alt="experience difference" />
            <div className="tab-title">
              {"Quality Infrastructure"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"We install a first-class network infrastructure, which is enhanced by our innovation software platform."}
            </div>
          </Col>
        </Row>

        <Row className="my-5">
          <Col xs={12} md>
            <img src={highspeed_soln} className="tab-img mr-3" alt="Integrated Wi-Fi Mesh" />
            <div className="tab-title">
              {"High-speed Solution"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"VostroNet deploys a 10Gbps fibre connection to buildings, which can be upgraded to 40, 80 or 100 Gbps to cater for future network usage"}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import bandwidth_assured from "../../images/ip-transit/info/bandwidth_assured.svg";
import endtoend from "../../images/ip-transit/info/endtoend.svg";
import peering_iptransit from "../../images/ip-transit/info/peering_iptransit.svg";

export default function IPTransit() {

  return (
    <div className="tab-connection">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Competitive IP transit options"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="tab-desc">
              {"Connect to our network via cross-connect at one of our data centre points of presence. We offer IP transit to business customers at competitive prices."}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-5">
          <Col xs={12} md>
            <img src={endtoend} className="tab-img mr-3" alt="high speed fibre" />
            <div className="tab-title">
              {"End-to-End"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"VostroNet manages the transit end-to-end, ensuring quality from point to point."}
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col xs={12} md>
            <img src={peering_iptransit} className="tab-img mr-3" alt="experience difference" />
            <div className="tab-title">
              {"Peering"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"We enable our customers to peer with the major content providers, connecting you with their networks."}
            </div>
          </Col>
        </Row>

        <Row className="my-5">
          <Col xs={12} md>
            <img src={bandwidth_assured} className="tab-img mr-3" alt="Integrated Wi-Fi Mesh" />
            <div className="tab-title">
              {"Bandwidth Assured"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Our IP Transit is uncongested, providing you with the high bandwidth you require."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAsyncWP } from "../../utils/wp-async";

const Blogs = ({ category }) => {
  const [result, loading, error] = useAsyncWP(3, category);

  function createMarkup(string) {
    return { __html: string };
  }

  if (loading) {
    return (<Fragment>
      <Container>
        <Container fluid className="my-5">
          <Row>
            <Col xs={12}>
              <a href="https://blog.vostronet.com/" rel="noopener noreferrer" className="blog-header font-black my-4">{"Read more articles from our Blog"}</a>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3 mx-auto" xs={12} lg={3}>
              <Container fluid className="height-100p blog font-black">
                <div className="mx-auto img-div">
                  <div className="blog-image" />
                </div>
              </Container>
            </Col>
            <Col className="mb-3 mx-auto" xs={12} lg={3}>
              <Container fluid className="height-100p blog font-black">
                <div className="mx-auto img-div">
                  <div className="blog-image" />
                </div>
              </Container>
            </Col>
            <Col className="mb-3 mx-auto" xs={12} lg={3}>
              <Container fluid className="height-100p blog font-black">
                <div className="mx-auto img-div">
                  <div className="blog-image" />
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>);
  }

  const columnSize = {
    xs: 12,
    lg: Math.floor(12 / ((result || {}).length) || 3),
  };

  return (
    <Container>
      <Container fluid className="my-5">
        <Row>
          <Col xs={12}>
            <a href="https://blog.vostronet.com/" rel="noopener noreferrer" className="blog-header font-black my-4">{"Read more articles from our Blog"}</a>
          </Col>
        </Row>
        <Row>
          {(result || []).map(({ title, subtitle, img, link, id, excerpt, slug, _embedded }) => {
            return (
              <Col className="mb-3" key={id} {...columnSize}>
                <a href={link} target="_blank" rel="noopener" className="font-black">
                  <Container fluid className="height-100p blog">
                    <div className="mx-auto img-div">
                      <img className="blog-image" src={(_embedded["wp:featuredmedia"][0] || {}).source_url} alt={slug} />
                    </div>
                    <div className="blog-title mx-3 mb-3" dangerouslySetInnerHTML={createMarkup(title.rendered)} />
                    <div className="blog-subtitle mx-3" dangerouslySetInnerHTML={createMarkup(excerpt.rendered)} />
                    <button href={link} target="_blank" rel="noopener" className="block-center read-more-btn btn btn-green">
                      {"Read more"}
                    </button>
                  </Container>
                </a>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
}

export default Blogs;
import React, { useRef, useEffect } from "react"

import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import InformationSection from "../components/sections/informational-section";
import LearnMoreHub from "../components/sections/hubs/learn-more-hub";

import DarkFibre from "../components/tabs/dark-fibre";
import EthernetService from "../components/tabs/ethernet-services";
import IPTransit from "../components/tabs/ip-transit";

import fibreNetworkIcon from "../images/dne/info/fibrenetwork.svg";
import futureProofIcon from "../images/dne/info/futureproof.svg";
import peeringIcon from "../images/dne/info/peering.svg";

// import bannerImage from "../images/";
import root from "window-or-global";
import { useStaticQuery, graphql } from "gatsby";


const bgQuery = graphql`query {
  file(relativePath: { eq: "headers/datanetwork_header.png"}) {
    childImageSharp {
      fluid(quality: 90, maxWidth: 4160) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}`

function getParameterByName(name, url = (root.location || {}).href) {
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const DataNetwork = () => {
  const bgData = useStaticQuery(bgQuery);
  const defaultTab = getParameterByName("tab");

  const myRef = useRef(null);
  const executeScroll = () => {
    if (root.scrollTo && myRef.current) {
      root.scrollTo({
        top: myRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }
  useEffect(() => {
    if (defaultTab) {
      executeScroll();
    }
  }, [defaultTab]);

  return (
    <Layout title="Data Network and Enterprise">
      <SEO title="Data Network and Enterprise - High Speed Network Solutions - VostroNet">
        <meta name="description" content="High Speed Network and Internet Solutions powered by VostroNet"/>
      </SEO>
      <Banner data={{
        title: {
          name: "Data Network and Enterprise",
          className: "font-white",
        },
        subtitles: [
          {
            name: `VostroNet deploys an advanced end-to-end fibre network. We manage the connection from the data centre to each individual access point and everything in between.`,
            className: "font-white",
          },
        ],
        buttonData: {
          name: "Learn more",
        },
        // img: bannerImage,
      }} bgData={bgData} />
      <div className="general py-5">
        <Container>
          <Row>
            <Col>
              <div className="title mb-3">
                {"Expanding Network"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="desc my-4">
                {"We own and operate an extensive fibre network, delivering a comprehensive solution based on first-class network infrastructure and enhanced by our innovative software platform. We offer dark fibre, lit ethernet layer-2 & layer-3 and IP transit solutions to customers."}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <InformationSection data={{
        title: "International Footprint",
        subtitle: "VostroNet's footprint extends across buildings and precincts across the Asia-Pacific.",
        subData: [
          // {
          //   title: "International Footprint",
          //   subtitle: "VostroNet's footprint extends across buildings and precincts across the Asia-Pacific",
          //   img: footprintIcon,
          // },
          {
            title: "Future-proof",
            subtitle: "We are dedicated to deploy a future-proof FTTx network We harness the latest technology to ensure our network meets future network demand",
            img: futureProofIcon,
          },
          {
            title: "Fibre Network",
            subtitle: "Fibre an excellent last mile technology, allowing for extremely high speed without additional barriers",
            img: fibreNetworkIcon,
          },
          {
            title: "Protective Peering",
            subtitle: "VostroNet connects directly with content providers, allowing you access to their networks.",
            img: peeringIcon,
          },
        ],
      }} />
      <div ref={myRef}>
        <Container className="py-5 tab-section">
          <Row>
            <Col>
              <Tabs defaultActiveKey={defaultTab || "dark-fibre"} id="SA-tab-section">
                <Tab eventKey="dark-fibre" title="Dark Fibre">
                  <DarkFibre />
                </Tab>
                <Tab eventKey="ethernet-service" title="Ethernet Services">
                  <EthernetService />
                </Tab>
                <Tab eventKey="ip-transit" title="IP Transit">
                  <IPTransit />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
      <Blog />
      <LearnMoreHub />
    </Layout>
  );
}

export default DataNetwork;

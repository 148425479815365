import React from "react"
import {Link} from "gatsby";
import {Container, Row, Col} from "react-bootstrap";

import managedInternetImage from "../../../images/learn-more-hub/managedinternetenvi.png";
import dataNetworkImage from "../../../images/learn-more-hub/datanetwork.png";
import ourinfrastructureImage from "../../../images/learn-more-hub/ourinfrastructure.png";
import residentialFibreImage from "../../../images/learn-more-hub/residentialfiber.png";
import securityImage from "../../../images/learn-more-hub/securityonyournetwork.png";
import usefuldataImage from "../../../images/learn-more-hub/usefuldata.png";

const LearnMoreHub = () => {
  return (
    <div className="learn-more-hub">
      <Container className="py-5">
          <Row className="mb-4">
            <Col>
              <div className="learn-more">
                {"Learn More"}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Link to="/internet-environments/" className="section-link">
                <img className="section-img block-center" src={managedInternetImage} alt="Internet Environments" />
                <div className="title py-2">{"Internet Environments"}</div>
              </Link>
            </Col>
            <Col xs={12} md={4}>
              <Link to="/residential-fibre-network/" className="section-link">
                <img className="section-img block-center" src={residentialFibreImage} alt="Residential Fibre Network" />
                <div className="title py-2">{"Residential Fibre Network"}</div>
              </Link>
            </Col>
            <Col xs={12} md={4}>
              <Link to="/data-network-and-enterprise/" className="section-link">
                <img className="section-img block-center" src={dataNetworkImage} alt="Data Network & Enterprise" />
                <div className="title py-2">{"Data Network & Enterprise"}</div>
              </Link>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Link to="/software-platform/" className="section-link">
                <img className="section-img block-center" src={usefuldataImage} alt="Useful Data Analytics" />
                <div className="title py-2">{"Useful Data Analytics"}</div>
              </Link>
            </Col>
            <Col xs={12} md={4}>
              <Link to="/about-us/" className="section-link">
                <img className="section-img block-center" src={securityImage} alt="About VostroNet" />
                <div className="title py-2">{"The VostroNet Advantage"}</div>
              </Link>
            </Col>
            <Col xs={12} md={4}>
              <Link to="/our-network/" className="section-link">
                <img className="section-img block-center" src={ourinfrastructureImage} alt="Our Infrastructure" />
                <div className="title py-2">{"Our Infrastructure"}</div>
              </Link>
            </Col>
          </Row>      </Container>
    </div>
  );
}

export default LearnMoreHub;